import { Currency } from "./currency";
import { Role } from "./role";

export enum TeamMemberStatus {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
}

export interface TeamMember {
  cid?: string;
  firstName?: string;
  lastName?: string;
  email: string;
  enabled: boolean;
  balance: Partial<Record<Currency, number>>;
  cardBalance: Partial<Record<Currency, number>>;
  spend: Partial<Record<Currency, number>>;
  numberOfCards: number;
  numberOfActiveCards: number;
  cardIssueLimit: number;
  note?: string;
  avatar?: string;
  totalUsd: number;
  teamlead: boolean;
  authority: Role;
  parentMember?: TeamMember;
  members?: TeamMember[] | null;
  autoRefillSettings: Partial<
    Record<
      Currency,
      {
        autoRefillEnabled: boolean;
        autoRefillThreshold: number;
        autoRefillAmount: number;
      }
    >
  >;
}

export interface TeamInvite {
  link: string;
  expireAt: number;
}

import { Component, Vue, Ref } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import debounce from "lodash.debounce";
import { formatMoney, copyTextClipboard, isEmpty, getFullName } from "@helpers";
import { Role } from "@/types/role";
import { TeamActions, TeamGetters } from "@store/modules/team/types";
import {
  profileModule,
  teamModule,
  userModule,
  walletModule,
} from "@store/namespaces";
import { TeamMember, TeamMemberStatus } from "@/types/team";
import { Currency } from "@/types/currency";
import { ProfileGetters } from "@store/modules/profile/types";
import { WalletGetters } from "@store/modules/wallet/types";
import { VForm } from "@/types/vuetify";
import { UserActions, UserGetters } from "@store/modules/user/types";
import { UserWebPreferencesKey } from "@/types/user";
import {
  TableHeaderItems,
  TableHeaderValue,
  TopBarFilter,
  TopBarFilterItems,
} from "@/types/components/filter-table-top-bar";
import { HtmlElementId, HtmlElementClassName } from "@/types/element";
import { StepAlias } from "@/types/productTour";
import mockTeamMemberHierarchy from "@/mockData/teamMemberHierarchy";
import { teamMemberStatuses } from "@config/team";

type TopBarTableFilter = TopBarFilter<"status", TeamMemberStatus>;

@Component({
  components: {
    TeamBalance: () => import("../components/TeamBalance/TeamBalance.vue"),
  },
})
export default class TeamMixin extends Vue {
  @Ref("confirmSecondFactorForm")
  private readonly confirmSecondFactorFormRef?: VForm;

  @teamModule.Action("fetchTeamInvite")
  private readonly fetchTeamInviteAction!: TeamActions["fetchTeamInvite"];
  @teamModule.Action("fetchTeamHierarchy")
  private readonly fetchTeamHierarchyAction!: TeamActions["fetchTeamHierarchy"];
  @teamModule.Action("refreshTeamInvite")
  private readonly refreshTeamInviteAction!: TeamActions["refreshTeamInvite"];
  @teamModule.Action("updateTeamMember")
  private readonly updateTeamMemberAction!: TeamActions["updateTeamMember"];
  @teamModule.Getter("totalTeamBalance")
  private readonly totalTeamBalanceGetter!: TeamGetters["totalTeamBalance"];
  @teamModule.Getter("teamHierarchyLoading")
  private readonly teamHierarchyLoadingGetter!: TeamGetters["teamHierarchyLoading"];
  @teamModule.Getter("teamHierarchy")
  private readonly teamHierarchy!: TeamGetters["teamHierarchy"];

  @userModule.Action("fetchUserWebPreferences")
  private readonly fetchUserWebPreferencesAction!: UserActions["fetchUserWebPreferences"];
  @userModule.Action("updateUserWebPreferences")
  private readonly updateUserWebPreferencesAction!: UserActions["updateUserWebPreferences"];
  @userModule.Getter("userWebPreferences")
  private readonly userWebPreferencesGetter!: UserGetters["userWebPreferences"];
  @userModule.Getter("userIsGhost")
  private readonly userIsGhostGetter!: UserGetters["userIsGhost"];
  @profileModule.Getter("profileEmail")
  private readonly profileEmailGetter!: ProfileGetters["profileEmail"];
  @profileModule.Getter("canViewTeamPage")
  private readonly canViewTeamPageGetter!: ProfileGetters["canViewTeamPage"];
  @profileModule.Getter
  private readonly profileSecondFactorEnabled!: ProfileGetters["profileSecondFactorEnabled"];
  @profileModule.Getter("userHasRole")
  private readonly userHasRoleGetter!: ProfileGetters["userHasRole"];
  @walletModule.Getter("walletBalance")
  private readonly walletBalanceGetter!: WalletGetters["walletBalance"];

  private teamHierarchyLoading = false;
  private testValue = [];
  private searchInput = "";
  private deletingTeamMembers: string[] = [];
  private updatingTeamMembers: string[] = [];
  private isSearchingTopBar = false;
  private isFilteringTopBar = false;

  private forceUpdateUserWebPreferencesKey = Date.now();

  private editedTeamMember = {
    email: "",
    note: "",
    secondFactorCode: "",
  };

  private confirmDialog: {
    showed: boolean;
    loading: boolean;
    data: {
      action: "delete";
      email: string;
    };
  } = {
    showed: false,
    loading: false,
    data: {
      action: "delete",
      email: "",
    },
  };

  private confirmSecondFactorCode:
    | {
        action: "note";
        data: {
          email: string;
          note: string;
        };
      }
    | {
        action: "delete";
        data: {
          email: string;
        };
      }
    | {
        action: "activate";
        data: {
          email: string;
        };
      }
    | null = null;

  private itemsPerPage = 15;
  private searchStr = "";
  private teamHierarchyReloadKey = Date.now();
  private topBarTableHeaderValue: Readonly<TableHeaderValue> = [];

  private topBarTableFilter: TopBarTableFilter = {
    status: teamMemberStatuses.map((value) =>
      Object.freeze({
        selected: true,
        value,
      })
    ),
  };

  private inviteLink = {
    loading: false,
    code: "",
    expireAt: 0,
  };

  private get isShowedMainIntro() {
    return this.$productTour.activeIntro.type === "product-tour";
  }

  private get canViewInviteUrl() {
    return (
      !this.userIsGhostGetter && !this.userHasRoleGetter([Role.ROLE_ACCOUNTANT])
    );
  }

  private get canViewTeamPage() {
    return this.isShowedMainIntro || this.canViewTeamPageGetter;
  }

  private get usedMockData() {
    return (
      this.isShowedMainIntro &&
      [StepAlias.MOCK_TEAM_LIST, StepAlias.MOCK_TEAM_ACTIONS].includes(
        this.$productTour.activeIntro.step.alias as StepAlias
      )
    );
  }

  protected get loadingTeam() {
    return this.teamHierarchyLoadingGetter || this.teamHierarchyLoading;
  }

  protected get loading() {
    return (
      this.isSearchingTopBar ||
      this.isFilteringTopBar ||
      this.loadingTeam ||
      this.deletingTeamMembers.length > 0 ||
      this.updatingTeamMembers.length > 0
    );
  }

  private get headers() {
    const headers: (DataTableHeader & {
      enabled?: boolean;
    })[] = [
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.table.header.name"),
        value: "name",
        sortable: false,
        enabled: !this.$vuetify.breakpoint.mobile,
      },
      {
        text: "Email",
        value: "email",
        sortable: false,
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.table.header.role"),
        value: "authority",
        sortable: false,
        enabled: this.userHasRoleGetter([
          Role.ROLE_ACCOUNTANT,
          Role.ROLE_TEAMLEAD,
          Role.ROLE_OWNER,
        ]),
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.table.header.status"),
        value: "enabled",
        sortable: false,
      },
      {
        text: this.$vuetify.lang.t(
          "$vuetify.dashboard.table.header.number_of_cards"
        ),
        value: "numberOfCards",
        sortable: false,
        align: "center",
      },
      {
        text: this.$vuetify.lang.t(
          "$vuetify.dashboard.table.header.number_of_active_cards"
        ),
        value: "numberOfActiveCards",
        sortable: false,
        align: "center",
      },
      {
        text: this.$vuetify.lang.t(
          "$vuetify.dashboard.table.header.expenditure"
        ),
        value: "spend",
        sortable: false,
        align: "center",
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.table.header.balance"),
        value: "balance",
        sortable: false,
        align: "center",
      },
      {
        text: this.$vuetify.lang.t(
          "$vuetify.dashboard.table.header.card_balance"
        ),
        value: "cardBalance",
        sortable: false,
        align: "center",
      },
      {
        text: this.$vuetify.lang.t(
          "$vuetify.dashboard.table.header.supervisor"
        ),
        value: "parentMember",
        sortable: false,
        align: "center",
        enabled: this.$vuetify.breakpoint.mobile,
      },
      {
        text: "",
        value: "action",
        align: "end",
        sortable: false,
        enabled: !this.userIsGhostGetter && !this.$vuetify.breakpoint.mobile,
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.delete"),
        value: "delete-action",
        sortable: false,
        enabled:
          this.$vuetify.breakpoint.mobile && this.isShowTeamMemberActionsMenu,
        filter: (_value, _search, item: TeamMember) => {
          return item.enabled;
        },
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.activate"),
        value: "activate-action",
        sortable: false,
        enabled:
          this.$vuetify.breakpoint.mobile && this.isShowTeamMemberActionsMenu,
        filter: (_value, _search, item: TeamMember) => {
          return !item.enabled;
        },
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.auto_refill.title"),
        value: "autorefill-action",
        sortable: false,
        enabled: this.$vuetify.breakpoint.mobile && this.isShowAutoRefillMenu,
        filter: (_value, _search, item: TeamMember) => {
          return this.checkCanAutoRefill(item);
        },
      },
    ];

    return headers.filter(({ enabled }) => enabled !== false);
  }

  private get htmlElementClassName() {
    return {
      teamWalletTransferFunds: HtmlElementClassName.teamWalletTransferFunds,
      teamCardIssueLimit: HtmlElementClassName.teamCardIssueLimit,
      teamActions: HtmlElementClassName.teamActions,
    };
  }

  private get htmlElementId() {
    return {
      teamInviteUrlField: HtmlElementId.teamInviteUrlField,
    };
  }

  private get fieldRules() {
    return {
      required: (v: string) =>
        !isEmpty(v) || this.$vuetify.lang.t("$vuetify.errors.required"),
    };
  }

  private get topBarFilterFields() {
    return [
      {
        title: this.$vuetify.lang.t("$vuetify.dashboard.table.header.status"),
        type: "checkbox",
        name: "status",
        items: teamMemberStatuses.map((value) => ({
          text: this.$vuetify.lang.t(
            `$vuetify.team.status.${value.toLowerCase()}`
          ),
          value,
        })),
      },
    ];
  }

  private get excludeTopBarHeaders() {
    return ["name", "delete-action", "action"];
  }

  private get topBarTableHeaderItems() {
    const items: TableHeaderItems = this.headers.filter(
      ({ value }) => !this.excludeTopBarHeaders.includes(value)
    );

    return items;
  }

  private get tableHeaders() {
    if (this.usedMockData) {
      return this.headers;
    }

    const topBarTableHeaderValue = this.topBarTableHeaderValue;

    return this.headers.filter(
      ({ value }) =>
        this.excludeTopBarHeaders.includes(value) ||
        topBarTableHeaderValue.some(
          ({ columnName, selected }) => columnName === value && selected
        )
    );
  }

  private get inviteUrl() {
    if (!this.inviteLink.code) return "";

    const { href } = this.$router.resolve({ name: "sign-up" });

    return `${window.location.origin}${href}?invite=${this.inviteLink.code}`;
  }

  private get teamBalance() {
    return formatMoney({
      value: this.totalTeamBalanceGetter,
      currency: Currency.USD,
      maximumFractionDigits: 2,
    });
  }

  private get flatTeamHierarchy() {
    const teamHierarchy = this.usedMockData
      ? mockTeamMemberHierarchy
      : this.teamHierarchy;

    if (!teamHierarchy) return [];

    const searchStr = this.searchStr.trim().toLowerCase();

    return this.flatMembers({
      members: teamHierarchy.members,
      membersOfLastParent: false,
      parentMember: teamHierarchy,
      searchStr,
      filter: this.topBarTableFilter,
    });
  }

  private get items() {
    if (
      this.isSearchingTopBar ||
      this.isFilteringTopBar ||
      this.teamHierarchyLoading
    ) {
      return [];
    }

    return this.flatTeamHierarchy;
  }

  private get isShowAutoRefillMenu() {
    return !this.userHasRoleGetter([Role.ROLE_ACCOUNTANT]);
  }

  private get isShowTeamMemberActionsMenu() {
    return !this.userHasRoleGetter([Role.ROLE_ACCOUNTANT]);
  }

  private get isShowConfirmSecondFactorCode() {
    return !!this.confirmSecondFactorCode;
  }

  private set isShowConfirmSecondFactorCode(showed) {
    if (!showed) {
      this.confirmSecondFactorCode = null;
    }

    this.editedTeamMember.secondFactorCode = "";
  }

  private get canEditNote() {
    return (
      !this.userIsGhostGetter && !this.userHasRoleGetter([Role.ROLE_ACCOUNTANT])
    );
  }

  private checkCanSetLimit(item: TeamMember) {
    return (
      item.enabled &&
      !this.userIsGhostGetter &&
      !this.userHasRoleGetter([Role.ROLE_ACCOUNTANT])
    );
  }

  private checkCanTransferMoney() {
    return (
      !this.userIsGhostGetter && !this.userHasRoleGetter([Role.ROLE_ACCOUNTANT])
    );
  }

  private checkCanAutoRefill(item: TeamMember) {
    return item.enabled && this.isShowAutoRefillMenu;
  }

  private flatMembers({
    nestingLevel = 1,
    flatIndex = 0,
    members,
    parentMember,
    membersOfLastParent = false,
    searchStr,
    filter,
  }: {
    nestingLevel?: number;
    flatIndex?: number;
    parentMember?: TeamMember;
    members?: TeamMember[] | null;
    membersOfLastParent?: boolean;
    searchStr: string;
    filter: TopBarTableFilter;
  }) {
    const list: (TeamMember & {
      memberIndex: number;
      nestingLevel?: number;
      flatIndex: number;
      membersOfLastParent: boolean;
      parentMember?: TeamMember;
      autoRefillCurrencies: Currency[];
    })[] = [];

    members?.forEach((member, memberIndex) => {
      const autoRefillCurrencies = Object.keys(
        member.cardBalance
      ) as Currency[];

      const searched =
        !searchStr ||
        member.email.includes(searchStr) ||
        member.note?.toLowerCase().includes(searchStr) ||
        this.getTeamMemberName(member).toLowerCase().includes(searchStr);

      const status = member.enabled
        ? TeamMemberStatus.ACTIVE
        : TeamMemberStatus.DELETED;

      const isAddedMember =
        filter.status.some(
          ({ selected, value }) => selected && value === status
        ) && searched;

      const itemMembers = this.flatMembers({
        members: member.members,
        membersOfLastParent: memberIndex === members.length - 1,
        flatIndex: isAddedMember ? flatIndex + 1 : flatIndex,
        nestingLevel: isAddedMember ? nestingLevel + 1 : nestingLevel,
        parentMember: member,
        searchStr,
        filter,
      });

      if (isAddedMember) {
        list.push(
          Object.freeze({
            ...member,
            members: [],
            memberIndex,
            membersOfLastParent,
            flatIndex,
            nestingLevel:
              itemMembers.length || nestingLevel > 1 ? nestingLevel : undefined,
            parentMember,
            autoRefillCurrencies,
          })
        );
      }

      list.push(...itemMembers);
    });

    return list;
  }

  private copyLinkClipboard() {
    copyTextClipboard(this.inviteUrl);

    this.$notify({
      type: "info",
      title: this.$vuetify.lang.t("$vuetify.info.copied"),
    });
  }

  private editTeamMember({ email, note }: { email: string; note?: string }) {
    this.editedTeamMember.email = email;
    this.editedTeamMember.note = note || "";
  }

  private async updateTeamMemberNote({
    email,
    note,
  }: {
    email: string;
    note: string;
  }) {
    this.updatingTeamMembers.push(email);

    try {
      await this.updateTeamMemberAction({
        email,
        label: note,
        secondFactorCode: this.editedTeamMember.secondFactorCode,
      });

      this.editedTeamMember.email = "";
      this.isShowConfirmSecondFactorCode = false;
      this.refreshTeamMembers();
    } finally {
      this.updatingTeamMembers = this.updatingTeamMembers.filter(
        (updatingEmail) => updatingEmail !== email
      );
    }
  }

  private onChangeTeamMemberNote({
    email,
    note,
  }: {
    email: string;
    note: string;
  }) {
    if (this.profileSecondFactorEnabled) {
      this.confirmSecondFactorCode = {
        action: "note",
        data: {
          email,
          note,
        },
      };
    } else {
      this.updateTeamMemberNote({
        email,
        note,
      });
    }
  }

  private onChangeTopBarTableFilter(val: TopBarTableFilter) {
    this.topBarTableFilter = val;
  }

  private onChangeTableHeaderValue(value: TableHeaderValue) {
    this.topBarTableHeaderValue = value;
  }

  private confirmSecondFactor() {
    if (!this.confirmSecondFactorFormRef?.validate()) return;

    switch (this.confirmSecondFactorCode?.action) {
      case "note":
        this.updateTeamMemberNote(this.confirmSecondFactorCode.data);
        break;

      case "delete":
        this.deleteTeamMember(this.confirmSecondFactorCode.data);
        break;

      case "activate":
        this.activateTeamMember(this.confirmSecondFactorCode.data);
        break;

      default:
        break;
    }
  }

  private getTeamMemberStatus({ enabled }: TeamMember) {
    if (!enabled) {
      return {
        text: this.$vuetify.lang.t("$vuetify.team.status.deleted"),
        color: "error",
      };
    }

    return {
      text: this.$vuetify.lang.t("$vuetify.team.status.active"),
      color: "success",
    };
  }

  private getTeamMemberName({
    firstName,
    lastName,
    email,
  }: Pick<TeamMember, "firstName" | "lastName" | "email">) {
    return getFullName({
      firstName,
      lastName,
      fallback: email,
    });
  }

  private onSuccessSetCardIssueLimit() {
    this.refreshTeamMembers();
  }

  private onSuccessTransferFunds() {
    this.refreshTeamMembers();
  }

  private onActivateTeamMember({ email }: { email: string }) {
    if (this.profileSecondFactorEnabled) {
      this.confirmSecondFactorCode = {
        action: "activate",
        data: {
          email,
        },
      };
    } else {
      this.activateTeamMember({
        email,
      });
    }
  }

  private async activateTeamMember({ email }: { email: string }) {
    this.updatingTeamMembers.push(email);

    try {
      await this.updateTeamMemberAction({
        active: true,
        email,
        secondFactorCode: this.editedTeamMember.secondFactorCode,
      });

      this.isShowConfirmSecondFactorCode = false;
      this.refreshTeamMembers();
    } finally {
      this.updatingTeamMembers = this.updatingTeamMembers.filter(
        (deletingEmail) => deletingEmail !== email
      );
    }
  }

  private async deleteTeamMember({ email }: { email: string }) {
    this.deletingTeamMembers.push(email);

    try {
      await this.updateTeamMemberAction({
        active: false,
        email,
        secondFactorCode: this.editedTeamMember.secondFactorCode,
      });

      this.isShowConfirmSecondFactorCode = false;
      this.refreshTeamMembers();
    } finally {
      this.deletingTeamMembers = this.deletingTeamMembers.filter(
        (deletingEmail) => deletingEmail !== email
      );
    }
  }

  private onConfirmDeleteTeamMember({ email }: { email: string }) {
    this.confirmDialog = {
      showed: true,
      loading: false,
      data: {
        action: "delete",
        email,
      },
    };
  }

  private onConfirmDialog() {
    if (this.confirmDialog.data.action === "delete") {
      this.onDeleteTeamMember(this.confirmDialog.data.email);
    }

    this.confirmDialog.showed = false;
  }

  private async onDeleteTeamMember(email: string) {
    if (this.profileSecondFactorEnabled) {
      this.confirmSecondFactorCode = {
        action: "delete",
        data: {
          email,
        },
      };
    } else {
      this.deleteTeamMember({
        email,
      });
    }
  }

  protected refreshTeamMembers(): void {
    this.teamHierarchyReloadKey++;
  }

  protected async fetchTeamMembers(): Promise<void> {
    await this.fetchTeamHierarchyAction({
      fromCache: false,
    });
  }

  private async fetchTeamInvite(options: { refresh?: boolean } = {}) {
    const { refresh = false } = options;

    if (this.inviteLink.loading || this.userIsGhostGetter) return;

    this.inviteLink.loading = true;

    try {
      const { link, expireAt } = await (!refresh
        ? this.fetchTeamInviteAction()
        : this.refreshTeamInviteAction());

      const [code] = link.split("=").reverse();

      this.inviteLink.code = code;
      this.inviteLink.expireAt = expireAt - 100;
      this.inviteLink.loading = false;
    } catch (error) {
      this.inviteLink.loading = false;
    }
  }

  private formatMoney(value: number, currency: Currency) {
    return formatMoney({
      value,
      currency,
      locale: this.$vuetify.lang.current,
    });
  }

  private async initWebPreferences() {
    this.teamHierarchyLoading = true;

    await this.fetchUserWebPreferencesAction({
      key: UserWebPreferencesKey.TEAM,
    });

    const { value } = this.userWebPreferencesGetter({
      key: UserWebPreferencesKey.TEAM,
    });

    console.log("value", value);

    const userWebPreferences = value as Partial<{
      topBarTableHeaderValue: TableHeaderValue;
      topBarTableFilter: TopBarTableFilter;
      itemsPerPage: number;
    }> | null;

    this.itemsPerPage = userWebPreferences?.itemsPerPage ?? this.itemsPerPage;
    this.topBarTableHeaderValue = this.topBarTableHeaderValue.map(
      ({ columnName, text, selected }) =>
        Object.freeze({
          columnName,
          text,
          selected:
            userWebPreferences?.topBarTableHeaderValue?.find(
              (item) => item.columnName === columnName
            )?.selected ?? selected,
        })
    );

    this.topBarTableFilter = Object.entries(
      this.topBarTableFilter
    ).reduce<TopBarTableFilter>((acc, [fieldName_, fieldValue_]) => {
      const fieldName = fieldName_ as keyof TopBarTableFilter;
      const fieldValue = fieldValue_ as TopBarFilterItems<TeamMemberStatus>;

      const fieldValueFromStorage = (
        userWebPreferences?.topBarTableFilter
          ? userWebPreferences.topBarTableFilter[fieldName]
          : undefined
      ) as TopBarFilterItems<string> | undefined;

      return {
        ...acc,
        [fieldName]: fieldValue.map(({ value, selected }) =>
          Object.freeze({
            value,
            selected:
              fieldValueFromStorage?.find((item) => item?.value === value)
                ?.selected ?? selected,
          })
        ),
      };
    }, this.topBarTableFilter);

    this.$watch(
      () => {
        return this.topBarTableHeaderValue;
      },
      () => {
        this.forceUpdateUserWebPreferencesKey++;
      }
    );

    this.$watch(
      () => {
        return this.topBarTableFilter;
      },
      () => {
        this.forceUpdateUserWebPreferencesKey++;
      }
    );

    this.$watch(
      () => {
        return [this.itemsPerPage, this.forceUpdateUserWebPreferencesKey].join(
          "-"
        );
      },
      debounce(() => {
        this.updateUserWebPreferencesAction({
          key: UserWebPreferencesKey.TEAM,
          value: {
            topBarTableHeaderValue: this.topBarTableHeaderValue,
            topBarTableFilter: this.topBarTableFilter,
            itemsPerPage: this.itemsPerPage,
          },
        });
      }, 500)
    );
  }

  private created() {
    this.topBarTableHeaderValue = this.topBarTableHeaderItems.map(
      ({ value, text }) =>
        Object.freeze({
          columnName: value,
          text,
          selected: true,
        })
    );
  }

  private initTeamMembersWatcher() {
    let requestKey = Date.now();

    const fetchTeamMembers = async () => {
      if (this.teamHierarchyLoadingGetter) return;

      const oldRequestKey = requestKey;

      await this.fetchTeamMembers();

      this.teamHierarchyLoading = false;

      if (requestKey !== oldRequestKey) {
        fetchTeamMembers();
      }
    };

    this.$watch(
      () => {
        return this.teamHierarchyReloadKey;
      },
      () => {
        requestKey++;
        fetchTeamMembers();
      },
      { immediate: true }
    );
  }

  private async mounted() {
    await this.initWebPreferences();
    this.initTeamMembersWatcher();

    if (this.canViewInviteUrl) {
      this.fetchTeamInvite();
    }
  }
}
